import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import ISCP from "../images/ISCP.png";
import IAPD from "../images/IAPD.png";
import IAPP from "../images/IAPP.png";
import IAACP from "../images/IAACP.png";
import IMAP from "../images/IMAP.png";
import IAAP from "../images/IAAP.png";
import IAED from "../images/IAED.png";
import { Pagination,Table} from 'antd';
import { FadeLoader } from 'react-spinners';

interface Association {
  tag: string;
  primaryTag: any;
  count: number;
  
}

interface User {
  primaryTag:any;
  userType: number;
  subRegionId: number;
  nationality: number;
}
interface association{
  tag: string;
  id: number;
  name: string;
  decription:string;
}
interface associationProps{
  association:association[],
  users:User[],
}
const Associations: React.FC<associationProps> = ({association,users}) => {
  const [associations, setAssociations] = useState<Association[]>([]);
  const [associationList, setAssociationList] = useState<User[]>([]);
  const storedUser = localStorage.getItem('user');
  const User = storedUser ? JSON.parse(storedUser) as User : null;
  const {darkMode}=useDarkMode()
  useEffect(() => {
   
        if (Array.isArray(users)) {
          const tempAssociations: { [key: string]: number } = {};
          users.forEach((user: any) => {
            const { primaryTag, subRegionId, nationality, userType } = user;
            const subRegionCheck = userType === 8 || subRegionId === User?.subRegionId;
            const nationalityCheck = nationality === User?.nationality;
            if (
              (primaryTag !== null && 10)&&(
              (User?.userType === 8 ) ||
              (((User?.userType === 5) || (User?.userType === 6 )||( User?.userType === 3)) && (nationalityCheck)) ||
              (((User?.userType === 2) || (User?.userType === 7)) && (subRegionCheck)))
            ) {
              tempAssociations[primaryTag] = (tempAssociations[primaryTag] || 0) + 1;
            }
          });
          setAssociations(
            Object.keys(tempAssociations).map((key) => ({
              primaryTag: key,
              tag:key,
              count: tempAssociations[key],
            }))
          );
        }
  }, []);
 
  const getAssociationNameById = (id: any) => {
    if (!id || !Array.isArray(association) || association.length === 0) {
        return '';
    }
    const associate = association.find(n => n.id === parseInt(id));
    return associate ? associate.name : '';
};
const getAssociationTagById = (id: any) => {
  if (!id || !Array.isArray(association) || association.length === 0) {
      return '';
  }
  const associate = association.find(n => n.id === parseInt(id));
  return associate ? associate.tag : '';
};
const getDescriptionById = (id: any) => {
  if (!id || !Array.isArray(association) || association.length === 0) {
      return '';
  }
  const associate = association.find(n => n.id === parseInt(id));
  return associate ? associate.decription : '';
};

const associationColors: { [key: string]: string } = {
  IAPD: 'text-purple-500',
  ISCP: 'text-yellow-600',
  IAPP:'text-green-700',
  IAACP:'text-green-300',
  IAAP:'text-amber-900',
  IAED:'',
  IMAP:'text-red-600'
};
const associationPics: { [key: string]: string } = {
  IAPD: IAPD,
  IAFLP: ISCP,
  ISCP: ISCP,
  IAPP:IAPP,
  IAAP:IAAP,
  IAACP:IAACP,
  IMAP:IMAP,
  IAED:IAED
};
  return (
    <div>
      <div className="grid grid-rows-1 md:grid-rows-1 lg:grid-rows-1 gap-4 p-12">
      {associations.length>0 &&   associations
      .filter(
        (association) =>
          association.primaryTag !== 10 &&
          association.primaryTag !== null &&
          association.primaryTag !== undefined
      )
.map((association, index) => (
    <Link key={index} to={`/association/${association.primaryTag}`}>
        <div className="flex flex-col justify-between bg-gray-200  p-3 rounded-lg    containerr ">
        <div className='flex flex-row justify-between '>
        <div className='p-2'>
           
        <p
        key={association.primaryTag || index}
        className={`text-blue-600 font-bold text-2xl ${associationColors[getAssociationTagById(association.primaryTag)] || ''}`}
      >
  {association?.primaryTag && getAssociationNameById(association.primaryTag)}
</p>
           <p className='font-bold text-xl'>({association?.primaryTag && getAssociationTagById(association.primaryTag)})</p>
         </div>
        <img
                src={associationPics[getAssociationTagById(association.primaryTag)] || ''}
                alt="Profile"
                className="h-25 w-25 object-cover rounded-lg p-2 "
           />
           
            </div>
            <div>
              <p className={`text-black ${darkMode?'text-gray-200':''}`}>{association?.primaryTag && getDescriptionById(association.primaryTag)}</p>
              <div className='flex flex-row'>
              <p className='font-semibold text-xl mt-6 '>Participants:</p>
            <p className=" py-2 border-2 border-green-500 rounded-lg bg-gray-300 px-4  h-10  w-20 text-center containerrr mt-6 mb-4 ml-2">
             
               {association.count}
            </p>
            </div>
            </div>
        </div>
    </Link>
))}

      </div>
      <div>
        
      </div>
    </div>
  );
};


interface User {
  userType: number;
  subRegionId: number;
  nationality: number;
}

interface UserListProps {
  users: any[];
}

const AssociationDetails: React.FC<UserListProps> = ({ users }) => {
  const { primaryTag } = useParams();
  const [individuals, setIndividuals] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  const storedUser = localStorage.getItem('user');
  const User = storedUser ? JSON.parse(storedUser) : null;

  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];

  // Filter users on mount or when dependencies change
  useEffect(() => {
    if (Array.isArray(users)) {
      const filteredData = users.filter((item: any) => {
        const subRegionCheck = item.subRegionId === User?.subRegionId;
        const nationalityCheck = item.nationality === User?.nationality;
        const organizationCheck = String(item?.primaryTag) === String(primaryTag);

        if (User?.userType === 8) {
          return organizationCheck;
        } else if ([5, 6, 3].includes(User?.userType)) {
          return nationalityCheck && organizationCheck;
        } else if ([2, 7].includes(User?.userType)) {
          return subRegionCheck && organizationCheck;
        } else {
          return false;
        }
      });

      setIndividuals(filteredData);
    } else {
      console.error('Invalid user data format.');
    }
  }, [users, User, primaryTag]);

  if (individuals.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <FadeLoader color="#367fd6" />
      </div>
    );
  }

  const getNationNameById = (id: any) => {
    const nation = nationList.find((n: any) => n.id === id);
    return nation ? nation.name : '';
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nationality',
      key: 'nationality',
      render: (record: any) => getNationNameById(record.nationality),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: any) => (
        <Link
          to={`/individual/${record.id}`}
          className="bg-green-400 text-white px-3 py-1 rounded-lg"
        >
          View More
        </Link>
      ),
    },
  ];

  return (
    <div className="p-6">
      <Table
        columns={columns}
        dataSource={individuals}
        pagination={{
          current: currentPage,
          pageSize: itemsPerPage,
          total: individuals.length,
          onChange: (page) => setCurrentPage(page),
        }}
        rowKey="id"
      />
    </div>
  );
};



export { Associations, AssociationDetails };
