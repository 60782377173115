import React, { useEffect, useState  } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebounce } from 'use-debounce';
import { CalendarOutlined } from '@ant-design/icons';
import { Modal, notification,Input, Pagination } from "antd";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSummits } from '../../contextApis/SummitProvider';
import { CiEdit } from "react-icons/ci";
import User from "../images/847-8474751_download-empty-profile.png";
import { useDarkMode } from "../../contextApis/DarkModeContext";
import { use } from "i18next";
import { stringify } from "querystring";
import axios from "axios";


interface Individual {
  id(id: any): unknown;
  name: string;
  picture: string;
  updatedBy:any;
  fullname: string;
  regionId: string;
  subRegionId: string;
  nationality: string;
  family_name: string;
  given_name: string;
  gender: string;
  email: string;
  upfPosition: string;
  position: string;
  biography: string;
  religion: string;
  primaryTag: string;
  honorifics: string;
}

const TitleStyle = {
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};
const NameStyle = {
  fontFamily: 'Goudy Old Style',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
};

interface User {
  position: any;
  leadershipStatus: any;
  id:any;
  upfPosition: string;
  name:string;
  userType: number;
  subRegionId:number;
  regionId:number;
  nationality:number;
}

interface Image {
  // ... image data structure from Strapi (if applicable)
  id: number;
  url: string;
}

interface Nation {
  id: number;
  name: string;
}
interface UserListProps {
  users: User[];
}
const IndividualDetails: React.FC<UserListProps>=({users})=> {
  const { id } = useParams();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { summit } = useSummits();
  const [individual, setIndividual] = useState<Individual | null>(null);
  const storedUser = localStorage.getItem('user');
  const loggedInUser = storedUser ? JSON.parse(storedUser) as User : null;
  const nationData = localStorage.getItem('nations');
  const nationList = nationData ? JSON.parse(nationData) : [];
  const[participants,setParticipants]=useState<any[]>([])
  const [userList, setUserList] = useState<any[]>([]);
  const[summits,setSummits]=useState<any[]>([])
  const [associationList, setAssociationList] = useState<Nation[]>([]);
  const subRegionData = localStorage.getItem('subRegions');
  const subList = subRegionData ? JSON.parse(subRegionData) : [];
  const regionData = localStorage.getItem('regions');
  const regionList = regionData ? JSON.parse(regionData) : [];
  const [filteredImage, setFilteredImage] = useState<string | null>(null);
console.log("sums",summit)
  let [loading, setLoading] = useState(true);
  const darkMode=useDarkMode()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const nationResponse = await fetch('https://api.upfafrica.org/admin/association/list', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            query: {},
            options: {
              select: ['id', 'name'],
              paginate: 166,
            },
            isCountOnly: false,
          }),
        });
        const nationData = await nationResponse.json();
        if (nationData?.data) {
          setAssociationList(nationData.data.data);
        } else {
          console.error('Invalid nation data:', nationData);
          throw new Error('Invalid nation data.');
        }
      } catch (error) {
        console.error('Error fetching nation data:', error);
      }
    };
    fetchData();
  }, []);

console.log("summits",summits)
  const getAssociationNameById = (id: any) => {
    if (!id || !Array.isArray(associationList) || associationList.length === 0) {
        return '';
    }
    const associate = associationList.find(n => n.id === parseInt(id));
    return associate ? associate.name : '';
};

  useEffect(() => {
    const fetchIndividual = async () => {
      try {
        const apiUrl = `https://api.upfafrica.org/admin/user/${id}`;
        const token = sessionStorage.getItem('token');
        const response = await fetch(
          apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*', // Add this line for CORS
                'Authorization': `Bearer ${token}` // Include 'Bearer' before the token
            }
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch individual data');
        }
        const data = await response.json();
        setIndividual(data.data);
        console.log("person",data.data)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchIndividual();
  }, [id]);

    // Strapi image fetching (if applicable)
    useEffect(() => {
      if (!individual?.picture) return; // Avoid unnecessary Strapi requests
  
      const fetchImage = async () => {
        try {
          const strapiImageUrl = `https://fm.upfafrica.org${individual.picture}`; // Adjust endpoint and URL structure based on Strapi
          const response = await axios.get<Image>(strapiImageUrl, {
            headers: {
              'Content-Type': 'multipart/form-data', // Adjust if Strapi requires specific headers for image retrieval
              Authorization: `Bearer ${"bc52ef2907c0212c0b06ebb5edf27cd615c242bfd7027050e393891120c9116a63a2dff3c4b4cc206bc8f06608c9f32a5e1c4acb854b11dc85530a5def61a2a6986d782bf2ffc725a41ca639573a6f70174f954a6ef6568717b58eb7d200b266f5987ab199446b8fcc9d064f1f6a645e591dce727a7489d817df67fcd1ded0fd"}` // Replace with your Strapi token
            }
          });
           
          setFilteredImage(response.data.url);
          setFilteredImage(strapiImageUrl);
          console.log('Filtered Image Url:', strapiImageUrl);
          
        } catch (error) {
          console.error('Error fetching image from Strapi:', error);
        }
      };
  
      fetchImage();
    }, [individual]);

    const displayImage = () => {
      if (!filteredImage) return null;
      console.log('Filtered Image :', filteredImage);
  
      return (
        <img
          src={filteredImage||User} // Use Strapi image URL from filteredImage
          alt={individual?.name || 'Image'} // Optional: Use user name for accessibility
          className="h-full object-cover rounded-lg mb-4 containerr"
        />
      
      );
    };
  if (!individual) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
        <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    );
    
  }
  
  const getNationNameById = (id: any) => {
    if (!id || !Array.isArray(nationList) || nationList.length === 0) {
        return '';
    }
    const nation = nationList.find(n => n.id === id);
    return nation ? nation.name : '';
};
const getSubNameById = (id: any) => {
  if (!id || !Array.isArray(subList) || subList.length === 0) {
      return '';
  }
  const Sub = subList.find(n => n.id === id);
  return Sub ? Sub.name : '';
};
const getRegionNameById = (id: any) => {
  if (!id || !Array.isArray(regionList) || regionList.length === 0) {
      return '';
  }
  const Region= regionList.find(n => n.id === id);
  return Region ? Region.name : '';
};
const getUpdateNameById = (id:any) => {
  if (!id || !isDataFetched || !Array.isArray(users)|| users.length === 0) {
    return '';
  }
  console.log('ID:', id);
  const user = users.find((user) => user.id === id);
  console.log('User:', user);
  return user ? user.name  : '';
};

const participatedSummits = summit.filter((summit: any) => 
  summit.participantsId && summit.participantsId.includes(individual.id)
);
const NameStyle = {
  fontWeight: 'bold',
  color: darkMode ? 'white' : 'black',
};


console.log("Participated Summits:", participatedSummits);
console.log("All Summits:", summit);
console.log("Individual ID:", individual.id);
return (
  <div className="container mx-auto px-4">
    {individual ? (
      <div className="space-y-8">
        <h2 style={{ fontSize: "24px" }} className="text-center font-bold">{individual.name}</h2>
        
        <div className="flex flex-col lg:flex-row gap-8">
          <div className="">
            {displayImage()}
          </div>
          
          <div className="lg:w-2/3">
            <div className="bg-gray-400  rounded-lg p-6 space-y-4" style={TitleStyle}>
              {[
                { label: 'Full Name', value: individual.name },
                { label: 'Region', value: individual?.regionId && getRegionNameById(individual?.regionId) },
                { label: 'Sub-region', value: individual?.subRegionId && getSubNameById(individual?.subRegionId) },
                { label: 'Nation', value: individual?.nationality && getNationNameById(individual?.nationality) },
                { label: 'Family Name', value: individual.name.split(' ')[1] },
                { label: 'Given Name', value: individual.name.split(' ')[0] },
                { label: 'Gender', value: individual.gender },
                { label: 'Email', value: individual.email },
                { label: 'UPF position', value: ((individual.position || individual.upfPosition) && (individual.position || individual.upfPosition).split(' ').slice(0, 6).join(' ')) },
                { label: 'Biography/CV', value: individual.biography },
                { label: 'Religion', value: individual.religion },
                { label: 'Organization', value: individual.primaryTag && getAssociationNameById(individual?.primaryTag) },
                { label: 'Honorifics', value: individual.honorifics },
              ].map(({ label, value }) => (
                <p key={label} className="flex flex-col sm:flex-row sm:justify-between">
                  <span className="font-semibold">{label}:</span>
                  <span className="mt-1 sm:mt-0">{value}</span>
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-gray-400 shadow-md rounded-lg p-6">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800 dark:text-white">Summits Participated</h3>
          {participatedSummits.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {participatedSummits.map((summit:any) => {
                const summitDate = new Date(summit.date);
                return (
                  <div key={summit.id} className="bg-blue-50 dark:bg-blue-900 rounded-md p-3 flex items-start">
                    <CalendarOutlined className="text-blue-500 h-4 w-4 mr-3 mt-1 flex-shrink-0" />
                    <div>
                      <h4 className="font-medium text-gray-800 dark:text-white">{summit.name}</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-300">
                        {summitDate.toLocaleDateString(undefined, {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-gray-600 dark:text-gray-400 italic">No summits participated.</p>
          )}
        </div>

        {isDataFetched && (
          <div className="bg-blue-900 bg-opacity-20 p-3 border rounded-md">
            <p className={`text-red-700 ${darkMode ? 'text-white' : ''}`}>
              Last UpdatedBy: {(individual?.updatedBy && getUpdateNameById(individual?.updatedBy)) || (individual?.updatedBy === loggedInUser?.id && loggedInUser?.name) || "Not Updated Yet"}
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className="sweet-loading flex justify-center items-center h-64">
        <FadeLoader color={darkMode ? '#ffffff' : '#000000'} loading={loading} />
      </div>
    )}
  </div>
);
}

interface ser {
  userType: number;
 subRegionId:string;
 nationality:string
}
interface UserListProps {
  users: User[];
}
const LeadershipGrid:React.FC<UserListProps>=({users})=> {
  console.log("usesrLeaderPage",users)
  const [leadersData, setLeadersData] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const storedUser = localStorage.getItem('user');
  const [debouncedFilterValue] = useDebounce(filterValue, 300);
  const user = storedUser ? JSON.parse(storedUser) : null;
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const [sortKey, setSortKey] = useState<string>('name'); // Default sorting key
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sorting order

  

  const GetAllUsers = () => {
    if (users) {
      const filteredData = users.filter((item: User) => {
        const ambassadorStatusCheck = item.leadershipStatus;
        if (ambassadorStatusCheck) {
          const nameMatch = item.name && item.name.toLowerCase().includes(debouncedFilterValue.toLowerCase());
          const positionMatch = (item.position && item.position.toLowerCase().includes(debouncedFilterValue.toLowerCase()))||(item.upfPosition && item.upfPosition.toLowerCase().includes(debouncedFilterValue.toLowerCase()));
          return nameMatch || positionMatch;
        }
        return false;
      });
      setLeadersData(filteredData);
    }
  };
  console.log("leaders",leadersData)
  useEffect(() => {
    if (users) {
      console.log('Debounced Filter Value:', debouncedFilterValue);
      GetAllUsers();
    }
  }, [debouncedFilterValue, users]);

  useEffect(() => {
    GetAllUsers();
  }, [sortKey, sortOrder]); // Call GetAllUsers when sortKey or sortOrder changes
 

  const deleteUser = async (id: any) => {
    try {
      const apiUrl = `https://api.upfafrica.org/admin/user/delete/${id}`;
      const token = sessionStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${token}`
        },
      });

      if (!response.ok) {
        console.error('Request failed:', response.status, response.statusText);
        throw new Error('Request failed.');
      }

      const responseData = await response.json();
      console.log('User deleted successfully:', responseData);

      // Display a success notification
      notification.open({
        message: 'User Deleted',
        description: 'The user has been deleted successfully.',
        duration: 3, // Duration in seconds
      });

      GetAllUsers();

      return responseData;
    } catch (error) {
      console.error('Error:', error);

      // Display an error notification
      notification.error({
        message: 'Error Deleting User',
        description: 'An error occurred while deleting the user.',
        duration: 3,
      });

      return error;
    }
  };

  const showModal = (id: any) => {
    setUserIdToDelete(id);
    setIsModalVisible(true);
  };

  const handleDelete = async () => {
    try {
      await deleteUser(userIdToDelete);
      setIsModalVisible(false);
    } catch (error) {
      // Handle errors if needed
    }
  };

  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/Leadershipinput");
  };

  const handleEdit = (id: any) => {
    navigate(`/EditAmbassador/${id}`);
  };

  const TitleStyle = {
    color: '#435782',
    fontFamily: 'Goudy Old Style',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  };
  const paginatedLeadersData = leadersData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page: number) => {
      setCurrentPage(page);
  };
  const toTitleCase = (str: any) => {
    if (str == null) {
        return ''; 
    }
    return str.replace(/\w\S*/g, (txt: any) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};
const canEditOrDelete = (userType: number, targetUserType: number) => {
  if (userType === 8 || userType === 1) return true;
  if ([2, 7].includes(userType)) return ![1, 8].includes(targetUserType); 
  if ([6, 3].includes(userType)) return ![1, 2, 7, 8].includes(targetUserType); 
  return false;
};

return (
  <div className=" w-[100%]">
    <div className="flex flex-row justify-between mt-6">
      {user && (user.userType === 8 || user.userType === 2) && (
        <button
          onClick={handleNext}
          className="w-2/12 h-10 mr-3 bg-slate-300 font-bold px-4 rounded focus:outline-none focus:shadow-outline-blue containerr "
        >
          + Add
        </button>
      )}
      <Input
        placeholder="Search by name, email, position, or nationality"
        value={filterValue}
        className='text-black containerrr'
        onChange={(e) => setFilterValue(e.target.value)}
        style={{ width: 300 }}
      />
    </div>
    {leadersData?.length === 0 ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>
          <FadeLoader color="#367fd6" className='w-96 h-96' />
        </div>
      </div>
    ) : (
      leadersData?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-12">
          {paginatedLeadersData?.map((item, id) => (
            <div key={id} className="bg-gray-200 rounded-lg p-4 w-80 h-96 containerrr overflow-hidden">
              <img
                src={`${item.picture ? `https://fm.upfafrica.org${item.picture}` : '/default-profile-image.png'}`} 
                alt={`Profile of ${item.name}`}
                className="w-full h-3/4 object-cover rounded-lg mb-4"
              />

              <div style={{ ...TitleStyle, fontSize: "24px" }} className="mt-10  flex justify-between items-center">
                <Link to={`/individual/${item?.id}`} className="containerrr ">{toTitleCase(item.name)}</Link>
                {canEditOrDelete(user?.userType, item?.userType) && (
                  <div className="flex flex-row">
                    <FontAwesomeIcon onClick={() => showModal(item?.id)} className="cursor-pointer  w-5 h-5" color="#FF0000" icon={faTrash} />
                    <CiEdit className="cursor-pointer ml-3" onClick={() => handleEdit(item?.id)} />
                  </div>
                )}
              </div>
            </div>
          ))}
          <Modal
            title="Delete User"
            visible={isModalVisible}
            onOk={handleDelete}
            onCancel={() => setIsModalVisible(false)}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{ style: { backgroundColor: '#FF0000', color: 'white' } }}
          >
            <p>Are you sure you want to delete this user?</p>
          </Modal>
        </div>
      ) : (
        <div className="min-h-screen flex flex-col justify-center items-center px-4">
          <div className="text-center max-w-lg">
            <FontAwesomeIcon icon={faLock} size="6x" className="text-red-600 mb-4" />
            <h1 className="text-3xl font-bold mb-2">Not Enough Permissions or No Data Exists</h1>
            <p className="mb-6">
              Please contact Superiors to confirm your Authorization Limits
            </p>
          </div>
        </div>
      )
    )}
    <Pagination
      current={currentPage}
      pageSize={itemsPerPage}
      total={leadersData.length}
      onChange={handlePageChange}
      style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}
    />
  </div>
);
};



export { IndividualDetails, LeadershipGrid };