import React, { useState, useRef, useEffect } from 'react';
import { 
  format, 
  startOfMonth, 
  endOfMonth, 
  startOfWeek, 
  endOfWeek, 
  addDays,
  addMonths,
  subMonths,
  isSameMonth, 
  isSameDay,
  setYear
} from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useDarkMode } from '../../contextApis/DarkModeContext';
import { useSummits } from '../../contextApis/SummitProvider';

interface Event {
  id: any;
  date: Date;
  name: string;
  status: string;
}

const Calendar: React.FC = () => {
  const { allSummit } = useSummits();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [error, setError] = useState<string | null>(null);
  const [isEditingYear, setIsEditingYear] = useState(false);
  const [yearInput, setYearInput] = useState(format(currentMonth, 'yyyy'));
  const yearInputRef = useRef<HTMLInputElement>(null);
  const { darkMode } = useDarkMode();

  const formattedEvents: Event[] = allSummit.map((event: any) => ({
    id: event.id,
    date: new Date(event.date),
    name: event.name,
    status: event.status
  }));

  useEffect(() => {
    if (isEditingYear && yearInputRef.current) {
      yearInputRef.current.focus();
    }
  }, [isEditingYear]);

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
    setYearInput(format(addMonths(currentMonth, 1), 'yyyy'));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
    setYearInput(format(subMonths(currentMonth, 1), 'yyyy'));
  };

  const handleYearClick = () => {
    setIsEditingYear(true);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numbers
    const value = e.target.value.replace(/[^\d]/g, '');
    if (value.length <= 4) {
      setYearInput(value);
    }
  };

  const handleYearSubmit = (e: React.KeyboardEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
    if ('key' in e && e.key !== 'Enter') {
      return;
    }

    const year = parseInt(yearInput);
    if (year >= 1900 && year <= 2100) {
      const newDate = setYear(currentMonth, year);
      setCurrentMonth(newDate);
    } else {
      // Reset to current year if invalid
      setYearInput(format(currentMonth, 'yyyy'));
    }
    setIsEditingYear(false);
  };

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);

  const renderHeader = () => {
    return (
      <div className="header flex items-center justify-between mb-4">
        <button
          onClick={prevMonth}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <div className="text-lg font-semibold flex items-center gap-2">
          {format(currentMonth, 'MMMM')}{' '}
          {isEditingYear ? (
            <input
              ref={yearInputRef}
              type="text"
              value={yearInput}
              onChange={handleYearChange}
              onKeyDown={handleYearSubmit}
              onBlur={handleYearSubmit}
              className="w-16 text-center border rounded px-1 py-0.5 focus:outline-none focus:border-blue-500"
              maxLength={4}
            />
          ) : (
            <span
              onClick={handleYearClick}
              className="cursor-pointer hover:bg-gray-100 px-2 py-0.5 rounded"
            >
              {format(currentMonth, 'yyyy')}
            </span>
          )}
        </div>
        <button
          onClick={nextMonth}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>
    );
  };

  const renderDays = () => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return (
      <div className="row flex">
        {days.map(day => (
          <div 
            className={`col flex justify-center items-center font-semibold w-full h-12`}
            key={day}
          >
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderCells = () => {
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = format(day, 'd');
        const dayEvents = formattedEvents.filter(
          event => 
            isSameDay(event.date, day) && 
            event.status === 'Approved'
        );

        days.push(
          <div
            className={`col flex flex-col items-center justify-start border p-2 min-h-24 w-full
              ${!isSameMonth(day, monthStart) 
                ? `${darkMode?'bg-blue-950':'bg-gray-100'}` 
                : isSameDay(day, new Date()) 
                  ? `${darkMode?'bg-blue-700':'bg-gray-100'}`
                  : ''
              }`}
            key={day.toString()}
          >
            <span className={`text-sm ${!isSameMonth(day, monthStart) ? 'text-gray-400' : ''}`}>
              {formattedDate}
            </span>
            <div className="mt-1 text-xs">
              {dayEvents.map(event => {
                const eventDate = new Date(event.date).setHours(0, 0, 0, 0);
                const currentDate = new Date().setHours(0, 0, 0, 0);
                const pastEvent = eventDate < currentDate;
                const presentEvent = eventDate === currentDate;
                const futureEvent = eventDate > currentDate;
                
                let statusColor = pastEvent 
                  ? 'bg-yellow-100 text-yellow-800'
                  : presentEvent 
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800';

                return (
                  <div 
                    key={event.id} 
                    className={`${statusColor} rounded p-1 mt-1 truncate text-center cursor-pointer hover:opacity-80`}
                  >
                    {event.name}
                  </div>
                );
              })}
            </div>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row flex" key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return rows;
  };

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className={`calendar flex flex-col justify-center items-center bg-white shadow-lg p-6 ml-6 rounded-lg pr-4 mt-9 ${darkMode ? 'bg-zinc-800' : ''}`}>
      {renderHeader()}
      <div className="body w-full">
        {renderDays()}
        {renderCells()}
      </div>
    </div>
  );
};

export default Calendar;